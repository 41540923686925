<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-07-09 10:29:48
 * @LastEditors: Seven
 * @LastEditTime: 2021-08-18 14:12:14
-->
<style lang="scss">
    @import './403.scss';
</style>

<template>
    <div class="error403">
        <div class="error403-body-con">
            <el-card>
                <div class="error403-body-con-title">4<span class="error403-0-span"><i type="android-lock"></i></span><span class="error403-key-span"><Icon size="220" type="ios-bolt"></Icon></span></div>
                <p class="error403-body-con-message">You don't have permission</p>
                <div class="error403-btn-con">
                    <el-button @click="goHome" size="large" style="width: 200px;" type="text">返回首页</el-button>
                    <el-button @click="backPage" size="large" style="width: 200px;margin-left: 40px;" type="primary">返回上一页</el-button>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Error403',
    methods: {
        backPage () {
            this.$router.go(-1);
        },
        goHome () {
            this.$router.push({
                name: 'wbHome'
            });
        }
    }
};
</script>
